import t from '@jetshop/intl';
import { DrawerTarget } from '@jetshop/ui/Modal/Drawer';
import { styled } from 'linaria/react';
import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Caret } from '../../../svg/angle-right.svg';
import { theme } from '../../Theme';

const Scroll = styled('div')`
  flex: 1 1 auto;
  position: relative;
`;

const NewDrawer = styled('nav')`
  background: white;
  position: fixed;
  display: flex;
  flex-direction: column;
  top: ${props => props.topPosition}px;
  height: calc(100vh - ${props => props.topPosition}px);
  z-index: 99999;
  max-width: 100%;
  ${theme.below.lg} {
    max-width: 90%;
  }

  width: ${props => props.size}px;
  transform: translateX(${props => (props.isOpen ? 0 : props.size)}px);
  transition: transform 0.2s ease-in-out;
  right: 0;

  &.left {
    left: 0;
    right: auto;
    transform: translateX(${props => (props.isOpen ? 0 : -props.size)}px);
  }
`;
const MenuWrapper = styled('div')`
  background: white;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 100%;
  opacity: ${props => (props.active ? 1 : 0)};
  overflow-y: ${props => (props.active ? 'auto' : 'hidden')};
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  transform: translateX(${props => (props.active ? 0 : -100)}%);
  transition: ${props => (props.active ? 0.4 : 0.2)}s ease-in-out;
  padding-bottom: 6rem;
  button,
  a {
    svg,
    img {
      width: 1rem;
      object-fit: contain;
    }
    span {
      font-family: 'M PLUS Rounded 1c', sans-serif;
      font-size: 14px;
      svg,
      img {
        width: 1.5rem;
        max-height: 1rem;
        margin-right: 0.5rem;
      }
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    padding: 1rem 20px;
    text-align: left;
    border-bottom: 1px solid #dedede;
    color: #333333;
    text-decoration: none;
    span {
      display: flex;
      align-items: center;
    }

    &:focus {
      outline: none;
    }
    &:active {
      background: #f9f9f9;
    }
  }
  .backbutton {
    svg {
      transform: rotate(-180deg);
      margin-right: 0.5rem;
    }
  }
`;

const MenuLevel = ({
  categories,
  active,
  goBack = () => {},
  parent = null,
  ...rest
}) => {
  const [activeId, setActiveId] = useState(null);
  const backHandler = () => {
    setActiveId(null);
  };
  useEffect(() => {
    if (!rest.isOpen && activeId) {
      //To avoid flicker on close
      setTimeout(() => {
        setActiveId(null);
      }, 300);
    }
  }, [rest.isOpen, activeId]);

  return (
    <>
      {active && (
        <>
          <MenuWrapper active={active}>
            {parent && (
              <button onClick={goBack} className="backbutton">
                <span>
                  <Caret />
                  {parent.parent ? (
                    <>
                      {t('Back to')}
                      {parent.parent.name}
                    </>
                  ) : (
                    t('Back')
                  )}
                </span>
              </button>
            )}
            {parent && parent.id !== 867 && (
              <Link onClick={rest.close} to={parent?.primaryRoute?.path}>
                <span>
                  <strong>Se alle</strong>
                </span>
                <Caret />
              </Link>
            )}
            {categories.map((cat, index) =>
              cat.hasSubcategories && cat?.subcategories ? (
                cat?.id === 329 ? (
                  <Link
                    onClick={rest.close}
                    to={cat?.primaryRoute?.path}
                    key={`link-${cat.id}`}
                  >
                    <span>{cat.name}</span>
                    <Caret />
                  </Link>
                ) : cat?.id === 867 ? (
                  <Link
                    onClick={rest.close}
                    to="/inspirasjon-og-raad"
                    key={`link-${cat.id}`}
                  >
                    <span>{cat.name}</span>
                    <Caret />
                  </Link>
                ) : (
                  <button key={index} onClick={() => setActiveId(cat.id)}>
                    <span>{cat.name}</span>
                    <Caret />
                  </button>
                )
              ) : (
                <React.Fragment key={index}>
                  <Link
                    onClick={rest.close}
                    to={cat.primaryRoute.path}
                    key={`link-${cat.id}`}
                  >
                    <span>{cat.name}</span>
                    <Caret />
                  </Link>
                </React.Fragment>
              )
            )}
            {/* <Link onClick={rest.close} to="/barbour-kampanje">
              Barbour Kampanje
            </Link> */}
            <Link onClick={rest.close} to="/gavekort">
              <span>Gavekort</span>
              <Caret />
            </Link>
            <Link onClick={rest.close} to="/stores">
              <span>Våre butikker</span>
              <Caret />
            </Link>
            <Link onClick={rest.close} to="/inspirasjon-og-rad-1/bonus-1">
              <span>Bonusprogram</span>
            </Link>
          </MenuWrapper>
          {categories?.map(cat =>
            cat.hasSubcategories && cat?.subcategories ? (
              cat?.id === 329 ? null : (
                <MenuLevel
                  key={`cat-${cat.id}`}
                  {...rest}
                  parent={{ ...cat, parent: parent }}
                  goBack={backHandler}
                  categories={cat.subcategories}
                  active={cat.id === activeId}
                />
              )
            ) : null
          )}
        </>
      )}
    </>
  );
};

export const PositionedDrawer = ({
  isOpen,
  size = 320,
  children,
  belowHeader = true,
  left
}) => {
  const topPosition = useMemo(() => {
    let topPos = 0;
    if (!belowHeader) return topPos;

    if (typeof document !== 'undefined') {
      const topBarHeight = document.getElementById('topBar')?.clientHeight ?? 0;
      topPos += document.getElementById('stickyHeader')?.clientHeight ?? 0;
      topPos += topBarHeight;
      if (window.pageYOffset < topBarHeight) {
        topPos -= window.pageYOffset;
      } else if (window.pageYOffset > topBarHeight) {
        topPos -= topBarHeight;
      }
    }
    return topPos;
  }, [belowHeader]);

  return (
    <NewDrawer
      isOpen={isOpen}
      size={size}
      topPosition={topPosition}
      className={left ? 'left' : ''}
    >
      {children}
    </NewDrawer>
  );
};

export default function FlyoutMenu({
  data,
  isSticky,
  size = 320,
  belowHeader = true,
  left = false
}) {
  if (!(data && data.categories)) return null;
  return (
    <DrawerTarget id="menu-drawer">
      {drawer => (
        <PositionedDrawer
          isOpen={drawer.isOpen}
          size={size}
          belowHeader={belowHeader}
          left={left}
        >
          <Scroll>
            <MenuLevel
              isOpen={drawer.isOpen}
              close={drawer.hideTarget}
              categories={data.categories}
              active={true}
            />
          </Scroll>
        </PositionedDrawer>
      )}
    </DrawerTarget>
  );
}
